import * as Sentry from '@sentry/react';
import * as React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { API_BASE_URL } from '@/old/config/api_routes';
import { UserSerializerSlim } from '@/schema';

import { version } from '../../package.json';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  release: `sara-frontend@${version}`,

  tracesSampleRate: 1.0,
  tracePropagationTargets: [API_BASE_URL],
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.5,

  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [API_BASE_URL, 'django', 'sendbird', 'sentry'],
    }),
    Sentry.extraErrorDataIntegration(),
  ],

  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'Unable to log in with provided credentials.',
    'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.', // related to media player for some reason
    'Request failed with status code 401',
    'Request failed with status code 403',
    /A network error occurred/i,
    /Network Error/i,
    /Non-Error promise rejection/i,

    // #region Bridge SSO
    /Failed to retrieve user profile from Arrowhealth/,
    // #endregion

    // #region Sendbird
    /Collection is not ready due to an error during initialization/i,
    /Connection is canceled/i,
    /Too many requests/i,
    /Command received no ack/i,
    'SendbirdError: Connection is closed. Please reconnect.', // Sudden ChatThread open->close
    // #endregion
  ],
});

export function identifySentryUser(
  user?: Pick<UserSerializerSlim, 'id' | 'email' | 'fullName'>,
) {
  if (!user) {
    Sentry.setUser(null);
    return;
  }

  Sentry.setUser({
    id: user.id,
    email: user.email,
    username: user.fullName,
  });
}

export function reportToSentry(
  error: Error,
  extra: Record<string, unknown> = {},
) {
  Sentry.captureException(error, {
    extra,
  });
}

export function logToSentry(
  message: string,
  extra: Record<string, unknown> = {},
) {
  Sentry.captureMessage(message, {
    extra,
  });
}
