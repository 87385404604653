import { Row } from '@tanstack/react-table';
import {
  format,
  formatDuration,
  intervalToDuration,
  secondsToMinutes,
} from 'date-fns';
import { isEmpty, isString } from 'lodash-es';
import { CopyIcon } from 'lucide-react';
import { useRef } from 'react';
import { toast } from 'sonner';

import { BillingInstance, TypeAc3Enum } from '@/schema';
import {
  formatDateShort,
  offsetTimezoneToLocal,
} from '@/utils/date-formatters';

import { Button } from '../ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { DropdownMenuItem } from '../ui/dropdown-menu';

interface JustificationDialogProps {
  isDropdownItem?: true;
  row: Row<BillingInstance>;
  onSuccess?: () => void;
}

const NonSupportCodeJustification = ({
  row,
}: Pick<JustificationDialogProps, 'row'>) => {
  const {
    type,
    rtmcycle: { startDate, endDate, patientInteractionDayCount },
  } = row.original;
  const start = offsetTimezoneToLocal(startDate);
  const end = offsetTimezoneToLocal(endDate);

  const startDateStr = formatDateShort(start);
  const endDateStr = formatDateShort(end);
  const dayRange = formatDuration(
    intervalToDuration({
      start,
      end,
    }),
    { format: ['days'] },
  );

  return (
    <>
      {type === TypeAc3Enum.Connect ? (
        <p>
          98975: Remote therapeutic monitoring (RTM) served to be clinically
          beneficial. Daily messages are appropriate to capture changes and
          avoid unnecessary decline. Patient educated on the use of SaRA Health
          RTM Platform on {startDateStr}. Patient articulated understanding of
          the purpose of RTM as a tool to: 1&#41; update their provider of
          clinically meaningful changes to their symptoms; 2&#41; encourage
          engagement with the POC; and 3&#41; improve access to their care.
          Monitoring was performed for {dayRange}.
        </p>
      ) : (
        <p>
          98977: Monitored relevant musculoskeletal data with{' '}
          {patientInteractionDayCount} days of data exchange via SaRA between{' '}
          {startDateStr} and {endDateStr}. Due to the dynamic nature of the
          patient&apos;s condition, it is beneficial to have awareness of their
          condition between visits. Responses were regularly monitored to reduce
          the likelihood of a decline in status and enable earlier intervention.
        </p>
      )}
      <p>
        The SaRA Health Platform was ruled by the FDA as a software as a medical
        device on 10/5/2023 in response to a 513(g) request.
      </p>
    </>
  );
};

const SupportCodeJustification = ({
  row,
}: Pick<JustificationDialogProps, 'row'>) => {
  const {
    unitsOf98981,
    patient: {
      user: { fullName },
    },
    calendarMonth,
    interactionLogs,
  } = row.original;

  const totalTime = Math.ceil(
    interactionLogs.reduce((sum, log) => sum + log.durationSecs, 0) / 60,
  );

  return (
    <>
      <h3>{fullName}</h3>

      <p>
        Patient was monitored during the month of{' '}
        {calendarMonth && format(offsetTimezoneToLocal(calendarMonth), 'MMMM')}{' '}
        via the SaRA Health Platform (SaRA). SaRA was ruled by the FDA as a
        software as a medical device on 10/5/2023 in response to a 513(g)
        request.
      </p>

      <p>Justification:</p>

      <ul className="flex list-disc flex-col gap-2 pl-6">
        {interactionLogs.map((log) => (
          <li key={log.id}>
            <p>
              {formatDateShort(log.interactedDatetime)}:{' '}
              {log.activityTypes?.join(', ')},{' '}
              {log.durationSecs < 60
                ? '<1 min'
                : `${secondsToMinutes(log.durationSecs)} mins`}
            </p>

            <p>Trends noted: {log.noteObservedTrends}</p>

            {isString(log.notePlanModification) &&
              !isEmpty(log.notePlanModification) && (
                <p>Influence clinical reasoning: {log.notePlanModification}</p>
              )}
          </li>
        ))}
      </ul>

      <p>Total time:</p>

      <ul className="flex list-disc flex-col gap-2 pl-6">
        <li>{totalTime} min</li>
      </ul>

      <p>Eligible to bill:</p>

      <ul className="flex list-disc flex-col gap-2 pl-6">
        <li>1 unit of 98980.</li>

        {unitsOf98981 && unitsOf98981 > 0 ? (
          <li>{unitsOf98981} unit(s) of 98981.</li>
        ) : null}
      </ul>
    </>
  );
};

export const JustificationDialog = ({
  row,
  isDropdownItem,
  onSuccess,
}: JustificationDialogProps) => {
  const code = row.original.type;
  const justificationRef = useRef<HTMLDivElement>(null);

  const copyToClipboard = async () => {
    if (justificationRef.current === null) {
      return;
    }

    const selection = window.getSelection();
    if (selection === null) {
      return;
    }

    const range = document.createRange();
    range.selectNodeContents(justificationRef.current);
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      // Now that we've selected the anchor text, execute the copy command
      await navigator.clipboard.writeText(selection.toString());

      row.toggleSelected(true);
      toast.success('Justification copied to clipboard.');
      onSuccess?.();
    } catch (err) {
      toast.error('Failed to copy text.', {
        description: (err as Error)?.message,
      });
      console.error('Failed to copy text: ', err);
    }

    // Remove the selection - note: should use removeRange(range) when it is supported
    selection.removeAllRanges();
  };

  return (
    <Dialog>
      <DialogTrigger asChild className="flex gap-2">
        {isDropdownItem ? (
          <DropdownMenuItem
            onSelect={(e) => {
              e.preventDefault();
            }}
          >
            <CopyIcon />
            Justification
          </DropdownMenuItem>
        ) : (
          <Button variant="ghost">
            <CopyIcon />
            Justification
          </Button>
        )}
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex gap-1">
            <p className="lowercase first-letter:capitalize">{code}</p> Code
            {code !== TypeAc3Enum.Support && (
              <span>(9897{code === TypeAc3Enum.Connect ? 5 : 7})</span>
            )}
            <span>
              Justification for patient {row.original.patient.user.fullName}
            </span>
          </DialogTitle>

          <DialogDescription asChild>
            <div>
              <p>
                You can copy/paste the below justification. All necessary
                details are included but you can edit the text inside of your
                EMR. You can either:
              </p>

              <ul className="flex list-disc flex-col gap-2 p-2 pl-6">
                <li>
                  Bill these RTM codes as part of an upcoming visit (aka add
                  them to a normal clinic note) OR
                </li>

                <li>
                  Create a new communication/encounter and bill them separately
                  from an in-person visit.
                </li>
              </ul>
            </div>
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col gap-2">
          <p>Justification Text</p>

          <div
            ref={justificationRef}
            className="flex flex-col gap-2 border p-4"
          >
            {code !== TypeAc3Enum.Support ? (
              <NonSupportCodeJustification row={row} />
            ) : (
              <SupportCodeJustification row={row} />
            )}
          </div>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Close</Button>
          </DialogClose>

          <DialogClose asChild>
            <Button type="submit" onClick={copyToClipboard}>
              Copy to Clipboard
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
