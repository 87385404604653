/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface PatientSummary
 */
export interface PatientSummary {
  /**
   *
   * @type {string}
   * @memberof PatientSummary
   */
  readonly summary: string;
}

/**
 * Check if a given object implements the PatientSummary interface.
 */
export function instanceOfPatientSummary(value: object): boolean {
  if (!('summary' in value)) return false;
  return true;
}

export function PatientSummaryFromJSON(json: any): PatientSummary {
  return PatientSummaryFromJSONTyped(json, false);
}

export function PatientSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatientSummary {
  if (json == null) {
    return json;
  }
  return {
    summary: json['summary'],
  };
}

export function PatientSummaryToJSON(value?: PatientSummary | null): any {
  if (value == null) {
    return value;
  }
  return {};
}
