/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { ProviderPatientRelationship } from './ProviderPatientRelationship';
import {
  ProviderPatientRelationshipFromJSON,
  ProviderPatientRelationshipFromJSONTyped,
  ProviderPatientRelationshipToJSON,
} from './ProviderPatientRelationship';
import type { ProviderSerializerSlim } from './ProviderSerializerSlim';
import {
  ProviderSerializerSlimFromJSON,
  ProviderSerializerSlimFromJSONTyped,
  ProviderSerializerSlimToJSON,
} from './ProviderSerializerSlim';
import type { RTMCycle } from './RTMCycle';
import {
  RTMCycleFromJSON,
  RTMCycleFromJSONTyped,
  RTMCycleToJSON,
} from './RTMCycle';
import type { RTMEnrollment } from './RTMEnrollment';
import {
  RTMEnrollmentFromJSON,
  RTMEnrollmentFromJSONTyped,
  RTMEnrollmentToJSON,
} from './RTMEnrollment';
import type { User } from './User';
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from './User';

/**
 *
 * @export
 * @interface PatchedPatient
 */
export interface PatchedPatient {
  /**
   *
   * @type {User}
   * @memberof PatchedPatient
   */
  readonly user?: User;
  /**
   *
   * @type {number}
   * @memberof PatchedPatient
   */
  readonly currentIntlogSecs?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedPatient
   */
  readonly sbChannelUrl?: string;
  /**
   *
   * @type {Array<ProviderPatientRelationship>}
   * @memberof PatchedPatient
   */
  readonly providerRelationships?: Array<ProviderPatientRelationship>;
  /**
   *
   * @type {ProviderSerializerSlim}
   * @memberof PatchedPatient
   */
  readonly primaryProvider?: ProviderSerializerSlim;
  /**
   *
   * @type {RTMCycle}
   * @memberof PatchedPatient
   */
  readonly currentRtmcycle?: RTMCycle;
  /**
   *
   * @type {RTMEnrollment}
   * @memberof PatchedPatient
   */
  readonly latestRtmenrollment?: RTMEnrollment;
  /**
   *
   * @type {boolean}
   * @memberof PatchedPatient
   */
  readonly isCurrentlyEnrolled?: boolean;
}

/**
 * Check if a given object implements the PatchedPatient interface.
 */
export function instanceOfPatchedPatient(value: object): boolean {
  return true;
}

export function PatchedPatientFromJSON(json: any): PatchedPatient {
  return PatchedPatientFromJSONTyped(json, false);
}

export function PatchedPatientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedPatient {
  if (json == null) {
    return json;
  }
  return {
    user: json['user'] == null ? undefined : UserFromJSON(json['user']),
    currentIntlogSecs:
      json['current_intlog_secs'] == null
        ? undefined
        : json['current_intlog_secs'],
    sbChannelUrl:
      json['sb_channel_url'] == null ? undefined : json['sb_channel_url'],
    providerRelationships:
      json['provider_relationships'] == null
        ? undefined
        : (json['provider_relationships'] as Array<any>).map(
            ProviderPatientRelationshipFromJSON,
          ),
    primaryProvider:
      json['primary_provider'] == null
        ? undefined
        : ProviderSerializerSlimFromJSON(json['primary_provider']),
    currentRtmcycle:
      json['current_rtmcycle'] == null
        ? undefined
        : RTMCycleFromJSON(json['current_rtmcycle']),
    latestRtmenrollment:
      json['latest_rtmenrollment'] == null
        ? undefined
        : RTMEnrollmentFromJSON(json['latest_rtmenrollment']),
    isCurrentlyEnrolled:
      json['is_currently_enrolled'] == null
        ? undefined
        : json['is_currently_enrolled'],
  };
}

export function PatchedPatientToJSON(value?: PatchedPatient | null): any {
  if (value == null) {
    return value;
  }
  return {};
}
