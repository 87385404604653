/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { PatientSummary } from '../models/index';
import { PatientSummaryFromJSON, PatientSummaryToJSON } from '../models/index';
import * as runtime from '../runtime';

export interface PatientSummaryRetrieveRequest {
  user: number;
}

/**
 *
 */
export class PatientApi extends runtime.BaseAPI {
  /**
   */
  async patientSummaryRetrieveRaw(
    requestParameters: PatientSummaryRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PatientSummary>> {
    if (requestParameters['user'] == null) {
      throw new runtime.RequiredError(
        'user',
        'Required parameter "user" was null or undefined when calling patientSummaryRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/{user}/summary/`.replace(
          `{${'user'}}`,
          encodeURIComponent(String(requestParameters['user'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientSummaryFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientSummaryRetrieve(
    requestParameters: PatientSummaryRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PatientSummary> {
    const response = await this.patientSummaryRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
